// @ts-nocheck
import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { userDataReducer } from './reducers/common/auth';
import { empersistReducer } from './reducers/common/persistReducer';
import { userInfoReducer } from './reducers/common/userInfoReducer';
import { filterCompanyReducer } from './reducers/tendergrid/FilterCompanyReducer';
import { seoRequestValueReducer, dropdownValReducer, dynamicMenuReducer, bidDropdownValReducer } from './reducers/common/DropdownReducer';
import advanceFilterReducer from './reducers/tendergrid/advanceFiltersReducer';
import CycleFilterReducer from './reducers/bidgrid/cycleFilterReducer';
import { checkoutReducer } from './reducers/tendergrid/checkoutReducer';
import socketReducer from './reducers/bidgrid/socketReducer';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';
import { userLogoutAction } from './actions/common/authAction';
import { calendarReducer } from './reducers/bidgrid/cycleFilterReducer';
import MisFilterReducer from './reducers/bidgrid/misFilterReducer';
const rootReducer = combineReducers({
  loginData: userDataReducer,
  userDetails: userInfoReducer,
  cycleFilter: CycleFilterReducer,
  misFilter: MisFilterReducer,
  companyData: filterCompanyReducer,
  dropdownCalVal: dropdownValReducer,
  dynamicMenuVal: dynamicMenuReducer,
  bidDropdownCalVal: bidDropdownValReducer,
  nettingData: advanceFilterReducer,
  checkoutData: checkoutReducer,
  seoValues: seoRequestValueReducer,
  socket: socketReducer,
  calendar: calendarReducer,
  persist: empersistReducer,
  // session: sessionReducer
});

const persistConfig = {
  key: 'empersistReducer',
  version: 1,
  storage,
  whitelist: [
    'dynamicMenuVal',
    'seoValues',
    "dropdownCalVal",
    "bidDropdownCalVal",
    'loginData',
    "cycleFilter",
    "userDetails",
    "misFilter",
    "persist",
    "myTenderList",
    "companyData",
    "nettingData",
    "checkoutData",
    "calendar"
  ]
};

const syncMiddleware = createStateSyncMiddleware({
  whitelist: [
    'loginData',
    "userDetails",
  ],
  broadcastChannelOption: {
    type: 'localstorage', // Use localStorage for state synchronization between tabs
    persistence: 'permanent', // Store the state permanently in localStorage
  },
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk, syncMiddleware)));
initMessageListener(store);


const persistor = persistStore(store);

window.addEventListener("storage", (event) => {
  if (event.key === "auth" && !event.newValue) {
    store.dispatch(userLogoutAction());
  }
});

export { persistor, store };