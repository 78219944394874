import { store } from "../Redux/store"
import { userBidLogoutAction, userLogoutAction, userStatLogoutAction } from "../Redux/actions/common/authAction";
import _ from "lodash";
import has from "lodash/has"

export const verifyAuth = response => {
    if (has(response.data, "validation_error")) {
        store.dispatch(userLogoutAction())
    }
}


export const verifyBidAuth = response => {
    if (has(response.data, "validation_error")) {
        store.dispatch(userBidLogoutAction)
    }
}


export const verifyStatAuth = response => {
    if (has(response.data, "validation_error")) {
        store.dispatch(userStatLogoutAction)
    }
}