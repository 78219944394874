const ROUTES = {

    // Common Routes

    LOGIN: "/apps/login",
    BD_LOGIN: "/document/login",
    SIGNUP: "/apps/signUp",
    PROFILE: "/apps/profile",
    TENDER_GRID: "/tendergrid",
    FORGOT_PASSWORD: "/apps/forgot-password",
    WALLET: "/apps/my-wallet",
    CHECKOUT: '/apps/checkout',
    CONDITION: 'apps/conditions',

    

    

    //Document modules Routes
    PREPAREFACTSHEET: "/document/PrepareFactSheet",
    MEETING: "/document/meetingschedules",
    DASHBOARD: "/document/dashboard",
    TASK_MANAGER: "/document/taskmanager",
    Task_assigine: "/document/taskassigine",
    MODELS: "/document/model",
    ADDUNIT: "/document/master/addUnit",
    JOBTITLE: "/document/master/jobtitle",
    PREFIX: "/document/master/prefix",
    ROLE: "/document/master/role",
    DESIGNATION: "/document/master/designation",
    TeamRequisitionDesignation: "/document/master/team-rq-designation",
    TeamRequisitionDesignationCategory: "/document/master/team-rq-category",

    CURRENCY: "/document/master/currency",
    LANGUAGE: "/document/master/language",
    TIMEZONE: "/document/master/timezone",
    ASSIGNSECTOR: "/document/master/assignsector",
    KEYWORD: "/document/master/keyword",
    COMPANY: "/document/add-document/company",
    LOGO: "/document/master/Logo",
    SUBSECTOR: "/document/master/subsector",
    TENDER_STATUS: "/document/master/documentstatus",
    BD_CLIENT: "/document/master/client",
    CONSORTIUM: "/document/master/consortium",
    NATIONALITY: "/document/master/nationality",
    GENDER: '/document/master/gender',
    DEPARTMENT_MANAGE: "/document/master/department",
    TENDER_PHASE: "/document/master/phase",
    TENDER_SCOPE: "/document/master/documentscope",
    FUNDING_AGENCY: "/document/master/fundingagency",
    COUNTRYLIST: "/document/master/countrylist",
    STATELIST: "/document/master/statelist",
    CITYLIST: "/document/master/citylist",
    CONTINENTLIST: "/document/master/continentlist",
    REGIONLIST: "/document/master/regionlist",
    EMPLOYEE: "/document/master/employee",
    EMPLOYEE_STATUS: "/document/master/employee_status",
    BLOODGROUP: "/document/master/bloodgroup",
    TENDER_SERVICES: "/document/master/documentservies",
    WISHLIST: "/document/wishlist",
    WORKLOAD_DISTRIBUTION: "/document/workdistribution",
    CALENDAR: "/document/calendar",
    LIVE_TENDERS: "/document/add-document",
    Personal_reminder: "/document/reminder",
    BD_PROFILE: "/document/profile",
    BD_EMP_PROFILE: "/document/profile/:id",
    New_Tenders: "/document/new-document",
    New_assigne: "/document/new-assigne",
    document_share: "/document/document-share",
    PROSPECTIVE_TENDER: "/document/prospective-document",
    CHATBOAT: "/document/chatboat",
    BD_TENDERDETAILS: "/document/documentdetails/:id",
    ConFiguration: '/document/Configuration/configuration',
    EMPLOYEELIST: '/document/pages/employeelist',
    BDREQUEST: "/document/pages/documentdetails",
    MIS:"/document/mis",
    // BD_TENDERDETAILS_id: "/bidgrid/bdtenderdetails/:id",

    //................

    // COMPANY_LIST: "/companylist",
    STATSGRID_INFO: "/apps/statgridinfo",
    DISCLAIMER: "/apps/disclaimer",
    TERMS_POLICY: "/apps/privacy-policy",
    CONDITIONS: "/apps/terms-and-conditions",
    SUB_SIGNUP: "/subdivision/signup",
    USERLIST:"/statgrid/userlist",
    ADDUSER:"/statgrid/adduser",
    PROFILES:"/statgrid/profile",
    ROLES:"/statgrid/roles",

    // ERROR: "/error",
    ROOT: "/",
    WILDCARD: "*"
}

export default ROUTES