// @ts-nocheck
import { DROPDOWN_REQUEST, BID_DROPDOWN_RESETALL, DROPDOWN_FAILED, DROPDOWN_RESETALL, BID_CURRENCY_SUCCESS, DROPDOWN_SUCCESS, SEO_REQUEST, SEO_SUCCESS, SEO_FAILED, DYNAMIC_MENU_REQUEST, DYNAMIC_MENU_SUCCESS, DYNAMIC_MENU_FAILED, BID_DROPDOWN_REQUEST, BID_CLIENT_SUCCESS, BID_DROPDOWN_FAILED, BID_CLIENT_FUNDING_AGENCY_SUCCESS, DROPDOWN_MENU_RESET, BID_COUNTRY_SUCCESS, BID_STATE_SUCCESS, BID_LEAD_COMPANY_SUCCESS, BID_SECTOR_SUCCESS, BID_TENDER_RESULT_STATUS, BID_All_USER_SUCCESS, BID_GENERATE_ID_SUCCESS } from "../../constants/common/DropdownConstant"
import { NavBarMenu } from '../../../Services/common/navbar/NavBarMenu'

export const dropdownValAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: DROPDOWN_REQUEST })
        dispatch({ type: DROPDOWN_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: DROPDOWN_FAILED, payload: error })
    }
}

export const dynamicMenuAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: DYNAMIC_MENU_REQUEST })
        dispatch({ type: DYNAMIC_MENU_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: DYNAMIC_MENU_FAILED, payload: error })
    }
}

export const dropdownValResetAction = () => async (dispatch) => {

    dispatch({ type: DROPDOWN_RESETALL })

}
export const dropdownMenuResetAction = () => async (dispatch) => {

    dispatch({ type: DROPDOWN_MENU_RESET })

}
export const bidClientAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_CLIENT_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}

export const bidTenderResultStatusAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_TENDER_RESULT_STATUS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}

export const bidFundingClientAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_CLIENT_FUNDING_AGENCY_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}

export const bidCountryAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_COUNTRY_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}
export const bidAllUserAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_All_USER_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}
export const bidGenerateIDAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_GENERATE_ID_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}
export const bidCurrencyAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_CURRENCY_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}

export const bidLeadCompanyAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_LEAD_COMPANY_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}

export const bidSectorAction = (data) => async (dispatch) => {

    try {
        dispatch({ type: BID_DROPDOWN_REQUEST })
        dispatch({ type: BID_SECTOR_SUCCESS, payload: data })
    }
    catch (error) {
        dispatch({ type: BID_DROPDOWN_FAILED, payload: error })
    }
}

export const bidDropdownActionReset = () => async (dispatch) => {

    dispatch({ type: BID_DROPDOWN_RESETALL })

}

const convertToEncoded = (str) => {
    // let updatedName = str.toLowerCase().replace(/\s+/g, ' ').split(' ').join('-').split(',').join('-').split('.').join('').split('(').join('').split(')').join('');
    let updatedName = str.toLowerCase().replace(/\s+/g, ' ')
        .replace(/[^a-zA-Z0-9\s&,.()]+/g, '')
        .split(' ')
        .map(word => word.replace(/[^a-zA-Z0-9]+/g, ''))
        .filter(word => word.length > 0)
        .join('-').split(' ').join('-').split('&').join('and').split(',').join('-').split('.').join('').split('(').join('').split(')').join('').split(" - ").join(" ");
    updatedName += '-tenders';
    return updatedName
}
const convertTosectorEncode = (str) => {
    let updatedName = str.toLowerCase().split(' ').join('-').split('&').join('and').split('(').join('').split(')').join('');
    return updatedName
}
const convertToStateEncoded = (str) => {
    let updatedName = str.toLowerCase().split(' ').join('-').split('&').join('and').split('(').join('').split(')').join('');
    updatedName += '-tenders';
    return updatedName
}

export const seoValuesAction = (id, type, type_name, data,tender_id,fin_year) => {
    return (dispatch) => {
        dispatch({ type: SEO_REQUEST });
        const formdata = new FormData();
        const formData = new FormData()
        formdata.append('page_id', id)
        formdata.append("tender_id", tender_id ?tender_id :"")
        formdata.append("fin_year", fin_year)
        formData.append("selct_type", type)
        let type_id

        if (type == "sector") {
            type_id = data?.sector?.find(val => convertTosectorEncode(val.sectName) === type_name)
            formData.append("select_id", parseInt(type_id?.fld_id))
            // formdata.append("sector_id", parseInt(type_id?.fld_id))
        } else if (type == "authority") {
            type_id = data?.client?.find(val => convertToEncoded(val.client_name) === type_name)
            console.log(type_id)
            // formdata.append("client_id", parseInt(type_id?.fld_id))
            formData.append("select_id", parseInt(type_id?.fld_id))
        } else if (type == "state") {
            type_id = data?.state?.find(val => convertToStateEncoded(val.state_name) === type_name)
            // formdata.append("state_id", parseInt(type_id?.state_id))
            // formData.append("state_id", parseInt(type_id?.state_id))
            formData.append("select_id", parseInt(type_id?.state_id))

        }

        if (type && type_id) {
            NavBarMenu?.getAdvanceSeo(formData).then((res) => {
                if (res?.data?.status === 1) {
                    dispatch({
                        type: SEO_SUCCESS,
                        payload: res?.data?.data,
                    });
                } else {
                    dispatch({
                        type: SEO_FAILED,
                        payload: 'Error message',
                    });
                }
            })
        } else {
            NavBarMenu.getSeoRequest(formdata)
                .then((res) => {
                    if (res?.data?.status === 1) {
                        dispatch({
                            type: SEO_SUCCESS,
                            payload: res?.data?.data,
                        });
                    } else {
                        dispatch({
                            type: SEO_FAILED,
                            payload: 'Error message',
                        });
                    }
                })
        }


    };
};
