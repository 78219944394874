import { APIService } from "../../APIService";
import { baseUrl, isAuth } from "../../../utils/configurable";
import { authHeader } from '../../../helper/authHeader'


const getCountry = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/Country_api`, data, { headers });
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}
const getState = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/State_api`, data, { headers });
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}
const getCity = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/City_api`, data, { headers });
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}


const allRegion = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/get_resgion_by_country`, data, { headers })
        return response

    }
    catch (error_msg) {
        return error_msg
    }
}

const getStateData = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/get_state_by_region_id`, data, { headers });
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}
const getStateDataByCountryId = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/State_api`, data, { headers });
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}
const getgrophicalRegion = async (data) => {
    try {
        const response = await APIService.post(`${baseUrl}/get_continent_list`, data);
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getGeoRegionCountryData = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/get_continent_country_list`, data, { headers });
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getHomeStates = async (data) => {
    // const headers = {'Content-Type' :"multipart/form-data"}
    try {
        const response = await APIService.post(`${baseUrl}/get_india_state_list`, data,);
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getTenderCategory = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/AllSectors_list`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getClientList = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/get_client_list`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getFundingList = async (data) => {
    const headers = { 'Content-Type': "multipart/form-data" }
    try {
        const response = await APIService.post(`${baseUrl}/AllFunding_agency_list`, data, { headers })
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getFinancialYear = async (data) => {
    try {
        const response = await APIService.post(`${baseUrl}/get_finencial_list`, data);
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getTenderType = async (data) => {
    try {
        const response = await APIService.post(`${baseUrl}/get_category_data`, data);
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getclientListState = async (data) => {
    try {
        const response = await APIService.post(`${baseUrl}/get_client_list_filter`, data);
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

const getWebsiteList = async (data) => {
    try {
        const response = await APIService.post(`${baseUrl}/get_website_list`, data);
        return response
    }
    catch (error_msg) {
        return error_msg
    }
}

export const DropdownValuesServices = {
    getCountry,
    getState,
    getCity,
    getStateData,
    allRegion,
    getgrophicalRegion,
    getGeoRegionCountryData,
    getHomeStates,
    getStateDataByCountryId,
    getTenderCategory,
    getClientList,
    getFundingList,
    getFinancialYear,
    getTenderType,
    getclientListState,
    getWebsiteList

};

