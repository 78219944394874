export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const LOGOUT = "LOGOUT"

export const BIDGRID_DATA_REQUEST = "BIDGRID_DATA_REQUEST"
export const BIDGRID_DATA_SUCCESS = "BIDGRID_DATA_SUCCESS"
export const BIDGRID_DATA_FAILED = "BIDGRID_DATA_FAILED"
export const LOGOUT_BID = "LOGOUT_BID"


export const STATGRID_DATA_REQUEST = "STATGRID_DATA_REQUEST"
export const STATGRID_DATA_SUCCESS = "STATGRID_DATA_SUCCESS"
export const STATGRID_DATA_FAILED = "STATGRID_DATA_FAILED"
export const LOGOUT_STAT = "LOGOUT_STAT"