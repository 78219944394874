import {
    DROPDOWN_REQUEST,
    DROPDOWN_FAILED,
    DROPDOWN_SUCCESS,
    SEO_REQUEST,
    SEO_FAILED,
    SEO_SUCCESS,
    DYNAMIC_MENU_REQUEST,
    DYNAMIC_MENU_SUCCESS,
    DYNAMIC_MENU_FAILED,
    BID_DROPDOWN_REQUEST,
    BID_CLIENT_SUCCESS,
    BID_DROPDOWN_FAILED,
    BID_CLIENT_FUNDING_AGENCY_SUCCESS,
    BID_COUNTRY_SUCCESS,
    BID_CURRENCY_SUCCESS,
    BID_LEAD_COMPANY_SUCCESS,
    BID_SECTOR_SUCCESS,
    BID_DROPDOWN_RESETALL,
    DROPDOWN_RESETALL,
    DROPDOWN_MENU_RESET,
    BID_TENDER_RESULT_STATUS,
    BID_All_USER_SUCCESS,
    BID_GENERATE_ID_SUCCESS
} from "../../constants/common/DropdownConstant"


export const dropdownValReducer = (state = { dropdownValues: {}, }, { type, payload }) => {
    switch (type) {
        case DROPDOWN_REQUEST:
            return { ...state }
        case DROPDOWN_SUCCESS:
            return { ...state, dropdownValues: payload }
        case DROPDOWN_RESETALL:
            return { ...state, dropdownValues: payload }
        case DROPDOWN_FAILED:
            return { ...state, error: payload }
        default: return state
    }
}

export const dynamicMenuReducer = (state = { dynamicMenu: [], }, { type, payload }) => {
    switch (type) {
        case DYNAMIC_MENU_REQUEST:
            return { ...state }
        case DYNAMIC_MENU_SUCCESS:
            return { ...state, dynamicMenu: payload }
        case DROPDOWN_MENU_RESET:
            return { ...state, dynamicMenu: payload }
        case DYNAMIC_MENU_FAILED:
            return { ...state, error: payload }
        default: return state
    }
}

const initialState = {
    seoValue: {
        page_title: '',
        meta_desc: '',
        meta_key: ''
    }
}


export const seoRequestValueReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case SEO_REQUEST:
            return { ...state }
        case SEO_SUCCESS:
            return { ...state, seoValue: { ...state.seoValue, ...payload } };
        case SEO_FAILED:
            return { ...state, error: payload };
        default:
            return state;
    }
};



export const bidDropdownValReducer = (state = {
    BidClient: [],
    BidFundingClientAgency: [],
    BidCountry: [],
    BidCurrency: [],
    BidLeadCompany: [],
    BidSector: [],
    BidTenderResultStatus: [],
    BidAllUsers: [],
    BidGenerateId: []

}, { type, payload }) => {
    switch (type) {
        case BID_DROPDOWN_REQUEST:
            return { ...state }
        case BID_CLIENT_SUCCESS:
            return { ...state, BidClient: payload }
        case BID_CLIENT_FUNDING_AGENCY_SUCCESS:
            return { ...state, BidFundingClientAgency: payload }
        case BID_COUNTRY_SUCCESS:
            return { ...state, BidCountry: payload }
        case BID_All_USER_SUCCESS:
            return { ...state, BidAllUsers: payload }
        case BID_GENERATE_ID_SUCCESS:
            return { ...state, BidGenerateId: payload }
        case BID_CURRENCY_SUCCESS:
            return { ...state, BidCurrency: payload }
        case BID_LEAD_COMPANY_SUCCESS:
            return { ...state, BidLeadCompany: payload }
        case BID_SECTOR_SUCCESS:
            return { ...state, BidSector: payload }
        case BID_TENDER_RESULT_STATUS:
            return { ...state, BidTenderResultStatus: payload }
        case BID_DROPDOWN_FAILED:
            return { ...state, error: payload }
        case BID_DROPDOWN_RESETALL:
            return {
                ...state,
                BidClient: [],
                BidFundingClientAgency: [],
                BidCountry: [],
                BidState: [],
                BidLeadCompany: [],
                BidSector: [],
                BidTenderResultStatus: [],
                BidAllUsers: [],
                BidGenerateId: []
            }
        default:
            return state
    }
}


