// @ts-nocheck
import React, { useEffect, useState, useRef, lazy, Suspense } from 'react'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { UserInfoServices } from '../Services/common/user/userinfo';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import { useSelector } from 'react-redux';
import { STRINGS } from "../Constants/Strings";
import ROUTES from 'Constants/Routes';
// import GetFreeTrial from 'Tendergrids/components/modal/GetFreeTrial';
// const CustomModal = lazy(() => import('../Tendergrids/components/modal/CustomModal'));
// const LoginPopupModal = lazy(() => import('../Tendergrids/components/modal/LoginPopupModal'));


const Footer = (props) => {
    const [show, setShow] = useState(false);
    const [data, setData] = useState('');
    const [search, setSearch] = useState(false)
    const [message, setMessage] = useState("")
    const [loginOpen, setLoginOpen] = useState(false)
    const { dynamicMenu } = useSelector((state) => state.dynamicMenuVal)
    const notify = (error) => toast.error(error);
    const notifySuccess = (msg) => toast.success(msg);
    const [val, setVal] = useState('')
    const location = useLocation()
    const [disableBtn, setDisableBtn] = useState(false)
    const inputRef = useRef(null);
    const path = location.pathname
    const currentYear = new Date().getFullYear();
    const handleKeyDown = (e) => {

        if (e.key === " ") {
            e.preventDefault();
        }
    };
    const emailChangeMethod = (e) => {
        setData(e.target.value)
    }
    const handleCheckMsg = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[a-z]{2,}$/i;
        if (data !== '') {
            if (emailRegex.test(data)) {
                setMessage('');
                setSearch(false)
            }
            else {
                setMessage('Please enter a valid email!');
                setSearch(true)
            }
        } else {
            setMessage('');
            setSearch(false)
        }
    }

    useEffect(() => {
        handleCheckMsg()
    }, [data])

    const freeTrial = async (e) => {
        e.preventDefault()
        setDisableBtn(true)
        const PayloadData = new FormData()
        if (data === "") {
            notify("Email is required");
        } else if (data && search) {
            notify("Valid Email is required");
        }
        else {
            PayloadData.append("email", data);
            try {
                const res = await UserInfoServices.getNewsLetter(PayloadData)
                if (res?.data?.status === 1) {
                    //    setMessage("")
                    setSearch(false)
                    notifySuccess(res?.data?.message)
                    setData("")
                } else {
                    notify("Already Subscribed")
                    // notify(res?.data?.message)
                }
            } catch (error) {
                console.log(error)
            }
        }
        setTimeout(() => {
            setDisableBtn(false)
        }, 2000);
    }

    const dynamaicHeadingFun = (heading) => {
        setShow(true)
        setVal(heading)
    }

    const handleNavigateCompany = (item) => {
        if (item.fld_id === '31' || item.fld_id === '32') {
            if (localStorage.getItem('auth')) {
                navigate(`/${item.action_url}`)
            } else {
                setLoginOpen(true)
            }
        } else {
            navigate(`/${item.action_url}`)
        }

    }

    useEffect(() => {
        if (props.show) {
            inputRef.current.focus();
        }
    }, [props.show]);


    const footerFiltered = (item) => {
        return item?.child?.filter(item => item?.menu_name !== STRINGS.OUR_PRODUCT)
    }

    const navigate = useNavigate()
    return (
        <>
            {(path !== ROUTES.LOGIN
                && path !== ROUTES.CHECKOUT
                && path !== ROUTES.ROOT
                && path !== ROUTES.SIGNUP
            )
                && path !== ROUTES.FORGOT_PASSWORD
                && path !== ROUTES.ERROR &&
                path !== ROUTES.BD_LOGIN &&
                <footer className="footer-style-two">
                    <div className="container">

                        <div className="upper-box">
                            <ul className="list-style-three">
                                <li>
                                    <span><LocalPhoneIcon /></span>
                                    <div className="contentBox">
                                        <div className='textHead'>Call Us</div>
                                        {/* <div className='address'>Have Any Question</div> */}
                                        <div className='address'><a href='tel:+919773356001' style={{color: "white"}}>+91 9773356001</a> , <a href='tel:+919773356002' style={{color: "white"}}>+91 9773356002</a></div>
                                    </div>
                                </li>
                                <li>
                                    <span><EmailIcon /></span>
                                    <div className="contentBox">
                                        <div className='textHead'>Mail Us</div>
                                        <div className='address'>Send your mail at</div>
                                        <div className='location'><a href='mailto:business@growthgrids.com' style={{color: "white"}}>business@growthgrids.com</a></div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className="widgets-section">
                            <div className="row clearfix">

                                <div className="footer-column col-md-5 col-sm-6 col-xs-12">
                                    <div className="footer-widget about-widget">
                                        <h2>About Us</h2>
                                        <div className="text">Tender Grid offers a wealth of information for companies wishing to search for and take advantage of tender opportunities. We have all the tender lists compiled on our user-friendly platform, which makes it easier for businesses to identify relevant tenders and interact with prospective contracts and projects. <br></br> <br></br>
                                            We are here to make your tender experience smooth, efficient, and rewarding!
                                        </div>
                                    </div>
                                </div>

                                <div className=" col-md-2 col-sm-6 col-xs-12">
                                    <div className="footer-widget links-widget">
                                        <h2>Services</h2>
                                        <div className="row clearfix">
                                            <div className="column col-sm-12 col-xs-12">
                                                <ul className="nav-list footer-hover">
                                                    {dynamicMenu?.filter(val => val.fld_id === "33").map((item, index) => {
                                                        return (
                                                            <>
                                                                {
                                                                    footerFiltered(item)?.slice(0, 1)?.map((item, index) => {
                                                                        return (
                                                                            <li key={index} onClick={() => { navigate(`/${item.action_url}`) }}><span>{item?.menu_name}</span></li>
                                                                        )
                                                                    })}
                                                            </>
                                                        )
                                                    })}


                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" col-md-2 col-sm-6 col-xs-12 phone-view-footer">
                                    <div className="footer-widget links-widget">
                                        <h2>Company</h2>
                                        <div className="row clearfix">
                                            <div className="column col-sm-12 col-xs-12">
                                                <ul className="nav-list footer-hover">

                                                    {dynamicMenu?.filter(val => val.fld_id === "29").map((item, index) => {
                                                        return (
                                                            <>
                                                                {item?.child?.map((item, index) => {
                                                                    return (
                                                                        <li key={index} onClick={() => { handleNavigateCompany(item) }}><span>{item?.menu_name}</span></li>
                                                                    )
                                                                })}
                                                            </>
                                                        )
                                                    })}
                                                    <li onClick={() => dynamaicHeadingFun('Get Free Trial')}><span className='get_trail_btn'>Get Free Trial</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className=" col-md-2 col-sm-6 col-xs-12 phone-view-footer">
                                    <div className="footer-widget links-widget">
                                        <h2>Privacy</h2>
                                        <div className="row clearfix">
                                            <div className="column col-sm-12 col-xs-12">
                                                <ul className="nav-list footer-hover">
                                                    {dynamicMenu?.filter(val => val.fld_id === "19").map((item, index) => {
                                                        return (
                                                            <>
                                                                {item?.child?.map((item, index) => {
                                                                    return (
                                                                        <li key={index} onClick={() => { navigate(`/${item.action_url}`) }}><span>{item?.menu_name}</span></li>
                                                                    )
                                                                })}
                                                            </>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lower-box">
                            <div className="row clearfix">

                                <div className="social-column col-md-5 col-sm-12 col-xs-12">
                                    <div className='titleHead'>Follow us on</div>
                                    <ul className="social-icon-three">
                                        <li>
                                            <a href="https://www.facebook.com/growthgrids" target="_blank" rel="noopener noreferrer">
                                                <span><FacebookOutlinedIcon /></span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/growthgrids" target="_blank" rel="noopener noreferrer">
                                                <span><TwitterIcon /></span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/growthgrids/" target="_blank" rel="noopener noreferrer">
                                                <span><InstagramIcon /></span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://in.linkedin.com/company/growthgrids" target="_blank" rel="noopener noreferrer">
                                                <span><LinkedInIcon /></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <div className="subscriber-column col-md-7 col-sm-12 col-xs-12">
                                    <div className="row clearfix">
                                        <div className="col-md-3 col-sm-12 col-xs-12">
                                            <h3>Subscribe to our Newsletter</h3>
                                        </div>
                                        <div className="col-md-9 col-sm-12 col-xs-12">
                                            <div className="subscribe-form">
                                                <form>
                                                    <div className="form-group">
                                                        <InputGroup className="" >
                                                            <Form.Control
                                                                placeholder="Enter your email.."
                                                                className='form-control-homepage'
                                                                onKeyDown={handleKeyDown}
                                                                onChange={emailChangeMethod}
                                                                value={data}
                                                                type="email"
                                                                ref={inputRef}
                                                            />
                                                        </InputGroup>

                                                        <button type="submit" className="theme-btn" onClick={freeTrial} disabled={disableBtn}>Subscribe</button>
                                                    </div>
                                                    <div className='message error' style={{ marginTop: "10px", color: 'red', textAlign: 'left' }}>
                                                        {message}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="copyright"> &copy;  2020-{currentYear}, Growth Grids, All rights reserved.</div>
                    </div>
                </footer>
            }
            {/* <Suspense fallback={<div></div>}><LoginPopupModal show={loginOpen} onHide={() => setLoginOpen(false)} /></Suspense> */}
            {/* <Suspense fallback={<div></div>}><GetFreeTrial show={show} onHide={() => setShow(!show)} Val={val} /></Suspense> */}
        </>
    )
}

export default Footer
