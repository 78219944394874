// // export function capitalizeExceptPrepositionsAndLowerCase(stringValue){

//     const prepositions = ['in', 'on', 'at', 'with', 'by', 'for', 'to', 'of', 'from','and', 'as'];
	
// // 		// Split the input string into an array of words
// // 		const words = stringValue?.split(' ');
	
// // 		// Capitalize the first letter of each word except for prepositions
// // 		const capitalizedWords = words?.map(word => prepositions.includes(word.toLowerCase()) ? word.toLowerCase() : word.charAt(0)?.toUpperCase() + word.slice(1)?.toLowerCase());
	
// // 		// Join the words back into a string
// // 		const resultString = capitalizedWords?.join(' ');
	
// // 		return resultString;

// // }

// export function capitalizeExceptPrepositionsAndLowerCase(stringValue) {
// 	const prepositions = ['in', 'on', 'at', 'with', 'by', 'for', 'to', 'of', 'from', 'and','as'];
  
// 	// Check if stringValue is a string
// 	if (typeof stringValue !== 'string') {
// 	  return stringValue; // Return as is if not a string
// 	}
  
// 	// Split the input string into an array of words
// 	const words = stringValue.split(' ');
  
// 	// Capitalize the first letter of each word except for prepositions
// 	const capitalizedWords = words.map((word) =>
// 	  prepositions.includes(word.toLowerCase())
// 		? word.toLowerCase()
// 		: word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
// 	);
  
// 	// Join the words back into a string
// 	const resultString = capitalizedWords.join(' ');
  
// 	return resultString;
//   }
  

export function capitalizeExceptPrepositionsAndLowerCase(stringValue, name) {
	const prepositions = ['in', 'on', 'at', 'with', 'by', 'for', 'to', 'of', 'from', 'and', 'as'];
  
	if (typeof stringValue !== 'string' && name !== "basic_desc" ) {
	  return stringValue; 
	}
	if (typeof stringValue !== 'string' && name !== "advance_desc" ) {
		return stringValue; 
	  }

	if(typeof stringValue === 'string' && name === "basic_desc" ){
		return stringValue;
	}
	if(typeof stringValue === 'string' && name === "advance_desc" ){
		return stringValue;
	}
	
	const words = stringValue.split(' ');
	const capitalizedWords = words.map((word) => {
	  const hasOpeningBracket = word.includes('(');
	  if (hasOpeningBracket) {
		const parts = word.split('(');
		const firstPart = parts[0];
		const secondPart = parts[1];
		const capitalizedSecondPart = secondPart.charAt(0).toUpperCase() + secondPart.slice(1);
		return firstPart + '(' + capitalizedSecondPart;
	  }
	  return prepositions.includes(word.toLowerCase())
		? word.toLowerCase()
		: word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	});
	const resultString = capitalizedWords.join(' ');
  
	return resultString;
  }
  