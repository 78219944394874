export const FILTER_INACTION_REQUEST = 'FILTER_INACTION_REQUEST'

export const FILTER_UPDATE_ALL_KEYS_OBJECTS = 'FILTER_UPDATE_ALL_KEYS_OBJECTS'

export const FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS = 'FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS'

export const FILTER_RESET_ARR_KEYS = 'FILTER_RESET_ARR_KEYS'

export const FILTER_RESET_INDIVIDUAL_ARR_OBJECTS = 'FILTER_RESET_INDIVIDUAL_ARR_OBJECTS'

export const FILTER_IS_UPDATE_RESET = 'FILTER_IS_UPDATE_RESET'

export const FILTER_INACTION_FAILED = 'FILTER_INACTION_FAILED'

export const FILTER_UPDATE_STATE_VAL = 'FILTER_UPDATE_STATE_VAL'
export const CALENDAR_REQUEST = "CALENDAR_REQUEST"
export const CALENDAR_SUCCESS = "CALENDAR_SUCCESS"
export const CALENDAR_FAILED = "CALENDAR_FAILED"

export const MIS_FILTER_INACTION_REQUEST = 'MIS_FILTER_INACTION_REQUEST'

export const MIS_FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS = 'MIS_FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS'

// export const FILTER_INACTION_REQUEST = 'FILTER_INACTION_REQUEST'

export const MIS_FILTER_UPDATE_ALL_KEYS_OBJECTS = 'MIS_FILTER_UPDATE_ALL_KEYS_OBJECTS'

// export const MIS_FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS = 'FILTER_UPDATE_INDIVIDUAL_OBJECTS_KEYS'

export const MIS_FILTER_RESET_ARR_KEYS = 'MIS_FILTER_RESET_ARR_KEYS'

export const MIS_FILTER_RESET_INDIVIDUAL_ARR_OBJECTS = 'MIS_FILTER_RESET_INDIVIDUAL_ARR_OBJECTS'

export const MIS_FILTER_IS_UPDATE_RESET = 'MIS_FILTER_IS_UPDATE_RESET'

export const MIS_FILTER_INACTION_FAILED = 'MIS_FILTER_INACTION_FAILED'
