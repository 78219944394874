// @ts-nocheck
import React, { useEffect, useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import WalletOutlinedIcon from "@mui/icons-material/WalletOutlined";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import "react-whatsapp-chat-widget/index.css";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import GgLogo from "../assests/img/logo gg.png";
import tenderGridLogo from "../assests/img/tender-logo.png";
import { profileImageAuth } from "../utils/configurable";
import close from "../assests/img/close.png";
import logo2 from "../assests/img/bhim.png";
import { userLogoutAction } from "../Redux/actions/common/authAction";
import ROUTES from "Constants/Routes";
// import { requestForToken } from '../firebase';
import { capitalizeExceptPrepositionsAndLowerCase } from "common/components/CapitalLetter/CapitalLetterWithoutPrepositions";
// import STATROUTES from "Statgrid/router/statgridRoutes";
// import { Notification } from "BidGrid/notification/notification";
const StatGridComp = lazy(() => import('./HeaderComp/StatGridComp'));
const TenderGridComp = lazy(() => import("./HeaderComp/TenderGridComp"));
const BidGrid = lazy(() => import("../Includes/HeaderComp/BidGrid"));
// const CustomModal = lazy(() => import("../Tendergrids/components/modal/CustomModal"));
// const LoginPopupModal = lazy(() => import("../Tendergrids/components/modal/LoginPopupModal"));
// const Notification = () => {
//   requestForToken();
//   // You might need to add dependencies or conditions for the useEffect hook based on your use case
//   // Rest of your component logic here
// }


// console.log(Notification(), '2222')

const setting = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

const Header = () => {



  const [show, setShow] = useState(false);
  const [anchorEl, setAnchorE1] = React.useState(null);
  const location = useLocation();
  const [openLogin, setOpenLogin] = useState(false);
  const path = location.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const { userInfo } = useSelector((state) => state.userDetails);
  const { bidgridData } = useSelector((state) => state.loginData);
  const { dynamicMenu } = useSelector((state) => state.dynamicMenuVal);
  const _ = require("lodash");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [val, setVal] = useState("");
  const [showOptionMenu, setShowOptionMenu] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [active, setActive] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [isLogo, setIsLogo] = useState(window.innerWidth <= 800);
  const [loggedIn, setLoggedIn] = useState(false)



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
      setIsLogo(window.innerWidth <= 800);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };
  const scrollToBottom = () => {
    const targetPosition = Math.max(
      0,
      document.documentElement.scrollHeight - window.innerHeight - 800
    );

    window.scroll({
      top: targetPosition,
      behavior: "smooth",
    });
  };

  const handleClick = (event) => {
    setAnchorE1(event.currentTarget);
  };

  const handledrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleClose = () => {
    setAnchorE1(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dynamaicHeadingFun = (heading) => {
    setShow(true);
    setVal(heading);
  };

  const handleHCSL = (e, obj) => {
    e.preventDefault();
    if (obj.hasOwnProperty("child")) {
      return;
    } else {
      navigate(ROUTES.ROOT + obj?.action_url);
    }
  };

  const handleProfileNavigation = (item) => {
    navigate(`/${item?.action_url}`);
    handleClose();
  };



  const handleLogout = () => {
    // localStorage.removeItem('sortState');
    navigate(ROUTES.LOGIN)
    dispatch(userLogoutAction());
  };



  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const controlNavbar = () => {
    if (window.scrollY > 0) {
      setShowOptionMenu(true);
    } else {
      setShowOptionMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, []);

  useEffect(() => {
    const pathArray = location.pathname.split('/');
    const extractedPath = `/${pathArray[1]}/${pathArray[2]}`;
    if (extractedPath === '/tendergrid/tender-list' || extractedPath === '/tendergrid/subscribe-live' || extractedPath === '/tendergrid/purchased-tenders' || extractedPath === '/tendergrid/last-24-hours' || extractedPath === '/tendergrid/archive' || extractedPath === '/tendergrid/my-favourite' || extractedPath === '/tendergrid/subscribe-archive') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [window.location.pathname]);

  const scrollDirection = useScrollDirection();

  const handleChangeTab = (id, e) => {
    setActive(id);
  };

  useEffect(() => {
    if (window.location.pathname.includes("/tendergrid")) {
      setActive(1);
    } else if (window.location.pathname.includes("/statgrid")) {
      setActive(2);
    }
    else if (window.location.pathname.includes("/bidgrid")) {
      setActive(3);
    }
  }, [window.location.pathname]);

  useEffect(() => {

    const handleStorageChange = (event) => {
      if (event.key === "persist:empersistReducer") {
        checkLoggedIn();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  //     const checkLoggedIn = () => {
  //         const storedUser = JSON?.parse(localStorage.getItem("persist:empersistReducer"));
  //         let userDetailsData = JSON?.parse(storedUser?.loginData)
  // // console.log(userDetailsData.userData.token,"ooooo");
  //         setLoggedIn(!!(userDetailsData.userData.token));
  //       };
  //       useEffect(() => {
  //         checkLoggedIn()
  //       },[])
  const checkLoggedIn = () => {
    const storedUser = JSON?.parse(localStorage.getItem("persist:empersistReducer"));
    if (storedUser) {
      let userDetailsData = JSON?.parse(storedUser?.loginData);

      if (userDetailsData && userDetailsData.userData && userDetailsData.userData.token) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    } else {
      setLoggedIn(false);
    }
  };

  useEffect(() => {
    checkLoggedIn();
  }, [])




  return (
    <>
      {/* <Notification /> */}
      {path !== ROUTES.LOGIN &&
        path !== ROUTES.SIGNUP &&
        path !== ROUTES.FORGOT_PASSWORD &&
        path !== ROUTES.ERROR &&
        path !== ROUTES.ROOT &&
        path !== ROUTES.CHECKOUT &&
        // path !== ROUTES.BD_LOGIN &&
        (
          <>
            <div
              className="topScoll"
              onClick={scrollToTop}
              style={{ display: scrollDirection === "down" ? "flex" : "none" }}
            >
              <ArrowUpwardIcon />
            </div>
            <div
              className="downScoll"
              onClick={scrollToBottom}
              style={{ display: scrollDirection === "up" ? "flex" : "none" }}
            >
              <ArrowDownwardIcon />
            </div>

            <header className={isActive ? "fixed-top remove-fixed-cls" : "fixed-top"}>
              <nav
                className={
                  isActive
                    ? "header navbar navbar-expand-lg navbar-light p-0"
                    : "header navbar navbar-expand-lg navbar-light p-0"
                }
                id="navbar_top"
                style={showOptionMenu ? { top: 0 } : { top: 0 }}
              >
                <div className="">
                  <div className="innerMenu">
                    <div className="navbar-brand">
                      {/* <img
                        src={GgLogo}
                        alt=""
                        onClick={(e) => {
                          const obj = dynamicMenu?.find(val => val.fld_id === "28");
                          handleHCSL(e, obj);
                        }}
                        width={50}
                      /> */}

                      <img
                        src={isMobile ? tenderGridLogo : GgLogo}
                        alt=""
                        onClick={(e) => {
                          const obj = dynamicMenu?.find(val => val.fld_id === "28");
                          handleHCSL(e, obj);
                        }}
                        width={45}
                      />

                      <div className="hide--buttons-media">
                        <div className="main-header-responsive">
                          <div className="social-link social_subscribe">
                            {localStorage.getItem("auth") ? (
                              <>
                                <Typography className="profile_fullname">
                                  {capitalizeExceptPrepositionsAndLowerCase(userInfo?.userfullname)}
                                </Typography>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Tooltip title="Account settings">
                                    <IconButton
                                      onClick={handleClick}
                                      size="small"
                                      style={{ background: "white" }}
                                      sx={{ ml: 2 }}
                                      aria-controls={
                                        open ? "account-menu" : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={open ? "true" : undefined}
                                    >
                                      <Avatar sx={{ width: 32, height: 32 }}>
                                        {userInfo?.profileimg !== null &&
                                          userInfo?.profileimg !== "" ? (
                                          <img
                                            src={profileImageAuth + userInfo?.profileimg}
                                            alt=""
                                            style={{
                                              width: "inherit",
                                              height: "inherit",
                                              background: "white",
                                            }} />
                                        ) : (
                                          userInfo?.firstname.charAt(0)
                                        )}
                                      </Avatar>
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <Menu
                                  anchorEl={anchorEl}
                                  id="account-menu"
                                  open={open}
                                  onClose={handleClose}
                                  onClick={handleClose}
                                  PaperProps={setting}
                                  transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                  }}
                                  anchorOrigin={{
                                    horizontal: "right",
                                    vertical: "bottom",
                                  }}
                                >
                                  {dynamicMenu
                                    ?.filter((val) => val.fld_id === "12")
                                    ?.map((item) => {
                                      return (
                                        <>
                                          {item?.child
                                            ?.filter(
                                              (val) =>
                                                val.fld_id !== "15" &&
                                                val.fld_id !== "14" &&
                                                val.fld_id !== "16" &&
                                                val.fld_id !== "40"
                                            )
                                            ?.map((item) => {
                                              let componentToRender;

                                              switch (item?.fld_id) {
                                                case "17":
                                                  componentToRender = (
                                                    <WalletOutlinedIcon fontSize="small" />
                                                  );
                                                  break;
                                                case "18":
                                                  componentToRender = (
                                                    <Settings fontSize="small" />
                                                  );
                                                  break;
                                                default:
                                                  componentToRender = null;
                                                  break;
                                              }
                                              return (
                                                <MenuItem
                                                  onClick={() => {
                                                    handleProfileNavigation(
                                                      item
                                                    );
                                                  }}
                                                >
                                                  {item?.fld_id === "13" ? (
                                                    <>
                                                      {userInfo?.profileimg !==
                                                        null &&
                                                        userInfo?.profileimg !==
                                                        "" ? (
                                                        <img
                                                          src={profileImageAuth + userInfo?.profileimg}
                                                          alt=""
                                                          className="menu_profile"
                                                        />
                                                      ) : (
                                                        <Avatar
                                                          sx={{
                                                            width: "6rem",
                                                            height: "6rem",
                                                          }}
                                                        >
                                                          {userInfo?.firstname.charAt(
                                                            0
                                                          )}
                                                        </Avatar>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <ListItemIcon>
                                                      {componentToRender}
                                                    </ListItemIcon>
                                                  )}
                                                  {item?.menu_name}
                                                </MenuItem>
                                              );
                                            })}
                                        </>
                                      );
                                    })}
                                  <Divider />

                                  <MenuItem onClick={handleLogout}>
                                    <ListItemIcon>
                                      <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                  </MenuItem>
                                </Menu>
                              </>
                            ) : null}
                          </div>
                          <div className="buttons-desktop">
                            {localStorage.getItem("auth") ? null : (
                              <div
                                className="text-end btn_getTrail"
                                onClick={() =>
                                  dynamaicHeadingFun("Get Free Trial")
                                }
                              >
                                <span
                                  className="mainButton"
                                  onClick={() => setShow(true)}
                                >
                                  Get Free Trial
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="main-menu-right">
                            <ul className="navbar-nav main-menu subscribe_menu">
                              {dynamicMenu
                                ?.filter(
                                  (val) =>
                                    !localStorage.getItem("auth") &&
                                    (val.fld_id === "25" || val.fld_id === "24")
                                )
                                .reverse()
                                .map((item, index) => {
                                  return (
                                    <li
                                      key={index}
                                      className="nav-item dropdown"
                                    >
                                      <span
                                        onClick={(e) => handleHCSL(e, item)}
                                        className="nav-link"
                                      >
                                        {item?.menu_name}
                                      </span>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>

                          <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                            onClick={handledrawer}
                          >
                            <span className="navbar-toggler-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      className="menuNavigation collapse navbar-collapse w-100"
                      id="navbarSupportedContent"
                    >
                      <div className="d-block top-menu hide--desktop">
                        <div className="row">
                          <div className="contact-item">
                            <span className=" me-5 ps-2">
                              <span className="text-dark">
                                <i className="fas fa-phone-alt me-2"></i> Sales
                                Number:{" "}
                                <a href="tel:+919773356002">+919773356002</a>
                              </span>
                            </span>
                            <span className=" me-5 ps-2">
                              <span className="text-dark">
                                <i className="fas fa-phone-alt me-2"></i>{" "}
                                Support Number:{" "}
                                <a href="tel:+919773356002">+91-9773356002</a>
                              </span>
                            </span>
                            <span className=" me-5 ps-2">
                              <span className="text-dark">
                                <i className="fas fa-envelope me-2"></i> Email:
                                <a href="mailto:business@growthgrids.com">
                                  business@growthgrids.com
                                </a>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <ul className="navbar-nav main-menu">
                        <li className="nav-item dropdown">
                          <span
                            onClick={(e) => {
                              const obj = dynamicMenu?.find(val => val.fld_id === "28");
                              handleHCSL(e, obj);
                              handleChangeTab(1);
                            }}
                            className={`nav-link ${active === 1 ? "active" : ""}`}
                          >
                            Tender Grid
                          </span>
                        </li>
                        <li className="nav-item dropdown">
                          <span
                            onClick={() => { handleChangeTab(3); localStorage.getItem('auth') ? navigate(ROUTES?.STATDASHBOARD) : navigate(ROUTES?.STAT_LOGIN) }}
                            className={`nav-link ${active === 2 ? "active" : ""}`}
                          >
                            Stat Grid (coming soon)
                          </span>
                        </li>

                        <li className="nav-item dropdown">
                          <span
                            onClick={() => { handleChangeTab(3); bidgridData?.data?.id ? navigate('/bidgrid/dashboard') : navigate(ROUTES.BD_LOGIN) }}
                            className={`nav-link ${active === 3 ? "active" : ""}`}
                          >
                            Bid Grid (coming soon)
                          </span>
                        </li>
                      </ul>
                      <div className="d-flex">
                        <div className="social-link social_subscribe">
                          {localStorage.getItem("auth") ? (
                            <>
                              <Typography className="profile_fullname">
                                {capitalizeExceptPrepositionsAndLowerCase(userInfo?.userfullname)}
                              </Typography>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip title="Account settings">
                                  <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    style={{ background: "white" }}
                                    sx={{ ml: 2 }}
                                    aria-controls={
                                      open ? "account-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                  >
                                    <Avatar sx={{ width: 32, height: 32 }}>
                                      {userInfo?.profileimg !== null &&
                                        userInfo?.profileimg !== "" ? (
                                        <img
                                          src={profileImageAuth + userInfo?.profileimg}
                                          alt=""
                                          style={{
                                            width: "inherit",
                                            height: "inherit",
                                            background: "white",
                                          }}
                                        ></img>
                                      ) : (
                                        // capitalizeExceptPrepositionsAndLowerCase(userInfo?.firstname)
                                        userInfo?.firstname.charAt(0)
                                      )}
                                    </Avatar>
                                  </IconButton>
                                </Tooltip>
                              </div>
                              <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={setting}
                                transformOrigin={{
                                  horizontal: "right",
                                  vertical: "top",
                                }}
                                anchorOrigin={{
                                  horizontal: "right",
                                  vertical: "bottom",
                                }}
                              >
                                {dynamicMenu
                                  ?.filter((val) => val.fld_id === "12")
                                  ?.map((item) => {
                                    return (
                                      <>
                                        {item?.child
                                          ?.filter(
                                            (val) =>
                                              val.fld_id !== "15" &&
                                              val.fld_id !== "14" &&
                                              val.fld_id !== "16" &&
                                              val.fld_id !== "40"
                                          )
                                          ?.map((item) => {
                                            let componentToRender;

                                            switch (item?.fld_id) {
                                              case "17":
                                                componentToRender = (
                                                  <WalletOutlinedIcon fontSize="small" />
                                                );
                                                break;
                                              case "18":
                                                componentToRender = (
                                                  <Settings fontSize="small" />
                                                );
                                                break;
                                              default:
                                                componentToRender = null;
                                                break;
                                            }
                                            return (
                                              <MenuItem
                                                onClick={() => {
                                                  handleProfileNavigation(item);
                                                }}
                                              >
                                                {item?.fld_id === "13" ? (
                                                  <>
                                                    {userInfo?.profileimg !==
                                                      null &&
                                                      userInfo?.profileimg !==
                                                      "" ? (
                                                      <img src={profileImageAuth + userInfo?.profileimg} alt="" className="menu_profile" />
                                                    ) : (
                                                      <Avatar
                                                        sx={{
                                                          width: "6rem",
                                                          height: "6rem",
                                                        }}
                                                      >
                                                        {userInfo?.firstname.charAt(
                                                          0
                                                        )}
                                                      </Avatar>
                                                    )}
                                                  </>
                                                ) : (
                                                  <ListItemIcon>
                                                    {componentToRender}
                                                  </ListItemIcon>
                                                )}
                                                {item?.menu_name}
                                              </MenuItem>
                                            );
                                          })}
                                      </>
                                    );
                                  })}
                                <Divider />

                                <MenuItem onClick={handleLogout}>
                                  <ListItemIcon>
                                    <Logout fontSize="small" />
                                  </ListItemIcon>
                                  Logout
                                </MenuItem>
                              </Menu>
                            </>
                          ) : null}
                        </div>
                        {localStorage.getItem("auth") ? null : (
                          <div
                            className="text-end btn_getTrail"
                            onClick={() => dynamaicHeadingFun("Get Free Trial")}
                          >
                            <span
                              className="mainButton"
                              onClick={() => setShow(true)}
                            >
                              Get Free Trial
                            </span>
                          </div>
                        )}
                        <div className="main-menu-right">
                          <ul className="navbar-nav main-menu subscribe_menu">
                            {dynamicMenu
                              ?.filter(
                                (val) =>
                                  !localStorage.getItem("auth") &&
                                  (val.fld_id === "25" || val.fld_id === "24")
                              )
                              ?.reverse()
                              ?.map((item, index) => {
                                return (
                                  <li key={index} className="nav-item dropdown">
                                    <span
                                      onClick={(e) => handleHCSL(e, item)}
                                      className="nav-link"
                                    >
                                      {item?.menu_name}
                                    </span>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
              <div
                className={
                  openDrawer ? "module_header_main" : "module_header_drawer"
                }
              >

                <div className="module_header">
                  <div className="innerMenu">
                    <div className="grid-icon">
                      <ul className="navbar-nav-phone">
                        <li className="nav-item dropdown">
                          <span
                            onClick={(e) => {
                              const obj = dynamicMenu?.find(
                                (val) => val.fld_id === "28"
                              );
                              handleHCSL(e, obj);
                              handleChangeTab(1);
                            }}
                            className={`nav-link ${active === 1 ? "active" : ""}`}
                          >
                            Tender Grid
                          </span>
                        </li>
                        <li className="nav-item dropdown">
                          <span
                            onClick={() => handleChangeTab(2)}
                            className={`nav-link ${active === 2 ? "active" : ""}`}
                          >
                            Stat Grid
                            <p className="coming-soon">(coming soon)</p>
                          </span>
                        </li>
                        <li className="nav-item dropdown">
                          <span
                            className={`nav-link ${active === 3 ? "active" : ""}`}
                          >
                            Bid Grid
                            <p className="coming-soon">(coming soon)</p>
                          </span>
                        </li>
                      </ul>
                    </div>

                    <div className="phone-media-respo">
                      <img
                        className="navbar_img"

                        src={isLogo ? GgLogo : tenderGridLogo}
                        // src={tenderGridLogo}
                        alt=""
                        onClick={(e) => {
                          const obj = dynamicMenu?.find(val => val.fld_id === "28");
                          handleHCSL(e, obj);
                        }}
                        width={160}
                        style={{ display: active === 1 ? "block" : "none", cursor: "pointer" }}
                      />
                      <img
                        className="navbar_img"
                        src={tenderGridLogo}
                        alt=""
                        onClick={(e) => {
                          const parentObject = dynamicMenu?.find((val) => val.fld_id === '8');
                          if (parentObject) {
                            const childObject = parentObject.child?.find((child) => child.fld_id === '11');
                            if (childObject) {
                              handleHCSL(e, childObject);
                            }
                          }
                        }}

                        width={160}
                        style={{ display: active === 2 ? "block" : "none", cursor: "pointer" }}
                      />
                      <img
                        className="navbar_img"
                        src={logo2}
                        alt=""
                        onClick={(e) => {
                          const obj = dynamicMenu?.find(
                            (val) => val.fld_id === "8"
                          );
                          handleHCSL(e, obj);
                        }}
                        width={50}
                        style={{ display: active === 3 ? "block" : "none", cursor: "pointer" }}
                      />
                      <img
                        className="navbar_close"
                        src={close}
                        alt=""
                        onClick={handleCloseDrawer}
                      />
                    </div>
                    {active === 1 && <Suspense fallback={<div></div>}><TenderGridComp openDrawer={openDrawer} openLogin={openLogin} setOpenLogin={setOpenLogin} setOpenDrawer={setOpenDrawer} /></Suspense>}
                    {active === 2 &&

                      <Suspense fallback={<div></div>}><StatGridComp openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} /></Suspense>
                    }
                    {
                      active === 3 &&
                      <Suspense fallback={<div></div>}><BidGrid /></Suspense>
                    }

                  </div>
                </div>
              </div>

            </header>

            {/* <Suspense fallback={<div></div>}>
              <LoginPopupModal
                show={openLogin}
                onHide={() => setOpenLogin(false)}
              />
            </Suspense>
            <Suspense fallback={<div></div>}><CustomModal show={show} onHide={() => setShow(false)} Val={val} /></Suspense> */}
          </>
        )
      }
    </>
  );
};

export default Header;
