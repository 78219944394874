const COMROUTES = {
    LOGIN: "login",
    SIGNUP: "signup",
    PROFILE: "profile",
    CHECKOUT: "checkout",
    FORGOT_PASSWORD: "forgot-password",
    WALLET: "my-wallet",
    STATSGRID_INFO: "statgridinfo",
    DISCLAIMER: "disclaimer",
    TERMS_POLICY: "privacy-policy",
    CONDITIONS: "terms-and-conditions",
    ERROR: "/apps/error",
    WILDCARD: "*"
}
export default COMROUTES