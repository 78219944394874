import { FETCH_USER_INFO_REQUEST, FETCH_USER_INFO_SUCCESS, FETCH_USER_INFO_FAILED, FETCH_USERBID_INFO_REQUEST, FETCH_USERBID_INFO_SUCCESS, FETCH_USERBID_INFO_FAILED, FETCH_USERSTAT_INFO_REQUEST, FETCH_USERSTAT_INFO_SUCCESS, FETCH_USERSTAT_INFO_FAILED } from "../../constants/common/userInfoConstant"

const initialState = {
    userInfo: {},
    userBidInfo: {},
    userStatInfo: {}
}


export const userInfoReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case FETCH_USER_INFO_REQUEST:
            return { ...state }
        case FETCH_USER_INFO_SUCCESS:
            return { ...state, userInfo: payload }
        case FETCH_USER_INFO_FAILED:
            return { ...state, error: payload }

        case FETCH_USERBID_INFO_REQUEST:
            return { ...state }
        case FETCH_USERBID_INFO_SUCCESS:
            return { ...state, userBidInfo: payload }
        case FETCH_USERBID_INFO_FAILED:
            return { ...state, error: payload }

            case FETCH_USERSTAT_INFO_REQUEST:
            return { ...state }
        case FETCH_USERSTAT_INFO_SUCCESS:
            return { ...state, userStatInfo: payload }
        case FETCH_USERSTAT_INFO_FAILED:
            return { ...state, error: payload }
        default: return state
    }
}

